<template>
  <div class=" flex-1 rounded-3xl bg-panel p-5 flex gap-2 flex-col flex-shrink-0 select-none">
    <div v-if="false" class="text-sm text-title font-semibold flex-shrink-0">{{ $t('checkoutcode') }}</div>
    <div v-if="false" class="flex items-center gap-3">
      <div v-if="instance.devices.checkout_code" class="text-lg font-semibold flex-shrink-0 text-white">
        {{ instance.devices.checkout_code | split3digits }}
      </div>
      <div v-else class="text-lg font-semibold flex-shrink-0 text-white opacity-20">
        {{ $t('nocheckoutcode') }}
      </div>
      <fw-button type="black" @click.native="refreshCode()">
        <fw-icon-refresh class=" text-white h-5 w-5" />
      </fw-button>
    </div>
    <div v-if="false" class="flex items-center">
      <div class="text-sm text-title font-semibold flex-shrink-0 flex-1">{{ $t('devices') }}</div>
      <fw-button type="black" :loading="loading" rounded="left" size="sm" icon="restart" @click.native="refresh()">
        <template #icon>
          <fw-icon-refresh class="w-4 h-4 text-white" />
        </template>
        <template #default>
          Atualizar
        </template>
      </fw-button>
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger="{ active }">
          <fw-button type="black" rounded="right" class="opacity-75 hover:opacity-100 flex items-center gap-2">
            {{ autoRefresh || 'Off' }} <fw-icon-chevron-down class="h-4 w-4"></fw-icon-chevron-down>
          </fw-button>
        </template>
        <b-dropdown-item paddingless aria-role="listitem">
          <fw-button type="basic-action" size="sm" expanded left label="Off" @click.native="activateAutoRefresh('off')">
            Off
          </fw-button>
        </b-dropdown-item>
        <b-dropdown-item v-for="(option, o) in refreshOptions" :key="o" paddingless aria-role="listitem">
          <fw-button
            type="basic-action"
            size="sm"
            expanded
            left
            :label="option.label"
            @click.native="activateAutoRefresh(option)"
          >
            {{ option.label }}
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-if="false" class="flex flex-shrink-0 gap-3 text-sm">
      <div class="color-inactive-tab font-bold">
        {{ $t('results') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'all',
          'color-inactive-tab': usersFilter !== 'all'
        }"
        @click="changeUsersFilter('all')"
      >
        {{ $t('all') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'answering',
          'color-inactive-tab': usersFilter !== 'answering'
        }"
        @click="changeUsersFilter('answering')"
      >
        {{ $t('running') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'closed',
          'color-inactive-tab': usersFilter !== 'closed'
        }"
        @click="changeUsersFilter('closed')"
      >
        {{ $t('submited') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'withdraw',
          'color-inactive-tab': usersFilter !== 'withdraw'
        }"
        @click="changeUsersFilter('withdraw')"
      >
        {{ $t('giveup') }}
      </div>
      <div
        class="cursor-pointer whitespace-nowrap"
        :class="{
          'text-primary font-semibold': usersFilter === 'noAnswer',
          'color-inactive-tab': usersFilter !== 'noAnswer'
        }"
        @click="changeUsersFilter('noAnswer')"
      >
        {{ $t('noanswer') }}
      </div>
    </div>
    <div class="flex flex-1 overflow-hidden">
      <LoadingPlaceholder v-if="loading" :number="5" />
      <div v-else-if="devices.length > 0" class="flex-1 relative">
        <div
          ref="answerslist"
          class="h-full overflow-y-scroll flex flex-col gap-3 justify-start rounded-lg hide-scrollbar"
        >
          <DeviceCard v-for="device in devices" :key="device.key" :device="device" />
        </div>
        <div v-if="usersOveflow" class="absolute bottom-0 left-0 right-0 h-10 dashboard-overflow-gradient"></div>
      </div>
      <div v-else class="flex-1 flex flex-col justify-center items-center">
        <fw-icon-rest class="color-icon-panel h-12 w-12 mb-2"></fw-icon-rest>
        <div class="text-title text-sm">
          {{ $t('nodevices') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeviceCard from '../cards/DeviceCard.vue'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ServiceExams from '../../services/ServiceExams'
export default {
  name: 'PanelDashboardDevices',
  components: {
    DeviceCard,
    LoadingPlaceholder
  },
  filters: {
    split3digits(value) {
      if (!value) return ''
      //create a code XXX-XXX
      return value.replace(/(\d{3})(\d{3})/, '$1-$2')
    }
  },
  props: {
    instance: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      usersFilter: 'all',
      usersOveflow: false,
      devices: [],
      loading: false,
      refreshOptions: [
        {
          label: '15s',
          value: 15000
        },
        {
          label: '30s',
          value: 30000
        },
        {
          label: '1m',
          value: 60000
        },
        {
          label: '5m',
          value: 300000
        },
        {
          label: '10m',
          value: 600000
        },
        {
          label: '15m',
          value: 900000
        },
        {
          label: '30m',
          value: 1800000
        }
      ],
      autoRefreshTimer: null,
      autoRefresh: ''
    }
  },
  mounted() {
    this.loadDevices()
  },
  methods: {
    async refreshCode() {
      try {
        let result = await ServiceExams.refreshDevicesCode(this.instance.key)
        console.log(result.checkout_code)
        if (result) {
          this.instance.devices.checkout_code = result.checkout_code
        }
      } catch (error) {
        console.error(error)
      }
    },
    activateAutoRefresh(option) {
      if (option === 'off') {
        this.autoRefresh = ''
        clearInterval(this.autoRefreshTimer)
        return
      }

      this.autoRefresh = option.label
      if (this.autoRefreshTimer != null) {
        clearInterval(this.autoRefreshTimer)
      }
      this.autoRefreshTimer = setInterval(() => {
        this.loadDevices()
      }, option.value)
    },
    refresh() {
      this.loadDevices()
    },
    verifyUsersOverflow() {
      let container = this.$refs.answerslist
      if (container) {
        this.usersOveflow = container.scrollHeight > container.clientHeight
        if (this.usersOveflow) {
          container.addEventListener('scroll', () => {
            if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
              this.usersOveflow = false
            } else {
              this.usersOveflow = true
            }
          })
        }
      } else {
        this.usersOveflow = false
      }
    },
    async loadDevices() {
      this.loading = true
      let devices = await FormServices.getDevices(this.instance.key)
      this.devices = devices
      this.loading = false
      this.verifyUsersOverflow()
    }
  }
}
</script>
<i18n>
  {
    "pt": {
      "checkoutcode": "Código de checkout",
      "devices": "Dispositivos",
      "results": "Resultados",
      "all": "Todos",
      "running": "Em curso",
      "submited": "Entregues",
      "giveup": "Desistências",
      "noanswer": "Sem resposta",
      "noanswers": "Sem avaliações para mostrar.",
      "noanswers_answering": "Sem avaliações a decorrer.",
      "noanswers_closed": "Sem respostas entregues.",
      "noanswers_withdraw": "Sem desistências.",
      "noanswers_noanswer": "Sem avaliações sem resposta.",
      "nodevices": "Sem dispositivos para mostrar.",
      "nocheckoutcode": "Sem código de checkout"
    },
    "en": {
      "checkoutcode": "Checkout code",
      "devices": "Devices",
      "results": "Results",
      "all": "All",
      "running": "Running",
      "submited": "Submited",
      "giveup": "Withdrawn",
      "noanswer": "No answer",
      "noanswers": "Without any answers to show.",
      "noanswers_answering": "Without any running evaluations.",
      "noanswers_closed": "Without any submited answers.",
      "noanswers_withdraw": "Without any withdraws.",
      "noanswers_noanswer": "Without any evaluations without answers.",
      "nodevices": "Without any devices to show.",
      "nocheckoutcode": "No checkout code"
    }
  }
</i18n>
