<template>
  <LayoutDashboardDefault dark>
    <div class="absolute top-5 right-5 flex gap-2.5">
      <fw-button @click.native="removeAll" type="dark" :loading="loading" :disabled="loading"> Apagar todos </fw-button>
      <fw-button
        @click.native="addExam"
        type="dark"
        icon="add"
        :loading="loading"
        :disabled="loading || exams.length == 4"
      >
        Adicionar
      </fw-button>
    </div>
    <LoadingPage v-if="loading" :dark="true" icon="exam" title="Informação do Exame"></LoadingPage>

    <div
      v-else-if="!loading && exams.length == 0"
      class="flex flex-col items-center text-center justify-center w-full h-full text-gray-200"
    >
      <div class="text-xl font-bold mb-3">{{ $t('no_exams') }}</div>
      <div class="text-base font-medium opacity-50">{{ $t('no_exams_instructions') }}.</div>
    </div>

    <div
      v-if="!loading && exams.length > 0"
      class="grid justify-center items-center w-full gap-4"
      :class="{
        'grid-cols-2': exams.length >= 2
      }"
    >
      <div v-for="exam in exams" :key="exam.key" class="min-h-0 min-w-0">
        <PanelExamInfo class="object-contain" :instance="exam" :language="language" :remaining-time="0"></PanelExamInfo>
      </div>
    </div>
  </LayoutDashboardDefault>
</template>

<script>
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import PanelExamInfo from '../components/panels/PanelExamInfo.vue'
import LoadingPage from '@/fw-modules/fw-core-vue/ui/components/loading/LoadingPage'
import LayoutDashboardDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDashboardDefault'
export default {
  name: 'ViewExamProjectionInfo',
  components: {
    PanelExamInfo,
    LoadingPage,
    LayoutDashboardDefault
  },
  data() {
    return {
      instance: null,
      remainingTime: 0, //we use this for a live update of the remaining time, the PanelDashboardControls will update the instance.remainingTime
      permissions: null,
      users: null,
      loading: true,
      answers: [],
      activity: [],
      language: 'pt',
      versions: [],
      pendingActivityMessages: [],
      versionsMap: {}, //here we store the index for each formID
      //activity that should be shown in the main activity section
      userModalKey: null, //data to show in the modal
      screenHeight: 500,
      exams: []
    }
  },
  computed: {
    showUserModal() {
      return this.userModalKey !== null
    },
    userModalData() {
      return this.userModalKey !== null ? this.answers.find(answer => answer.user.key == this.userModalKey) : null
    },
    userActivity() {
      return this.activity.filter(activity => activity.by_user_key == this.userModalKey)
    },
    waitingForUsers() {
      return this.instance.status == 'waiting' || this.instance.status == 'scheduled' || this.instance.status == 'draft'
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    instanceID() {
      return this.$route.params.id ? this.$route.params.id : null
    },
    examWSMessages() {
      return this.$store.state.session.unreadExamWsMessages
    }
  },
  mounted() {
    console.log('mounted')
    //get exam keys in the url
    let url_keys = this.$route.query.keys
    //local storage keys
    let local_storage_keys = localStorage.getItem('exam_info_keys')
    if (url_keys) {
      if (local_storage_keys) {
        let keys = local_storage_keys.split(',')
        //add only the new keys
        if (keys.indexOf(url_keys) == -1) {
          keys = keys.concat(url_keys.split(','))
          localStorage.setItem('exam_info_keys', keys.join(','))
        }
      } else {
        localStorage.setItem('exam_info_keys', url_keys)
      }
    }
    this.loadExamsInfo()
  },
  methods: {
    removeAll() {
      this.$buefy.dialog.confirm({
        title: 'Apagar todos os exames',
        message: 'Tem a certeza que deseja apagar todos os exames?',
        confirmText: 'Apagar',
        cancelText: 'Cancelar',
        onConfirm: async () => {
          localStorage.removeItem('exam_info_keys')
          localStorage.removeItem('exam_info_codes')
          this.exams = []
        }
      })
    },
    addExam() {
      //ask for exam 6 digit code
      this.$buefy.dialog.prompt({
        title: 'Adicionar Exame',
        message: 'Insira o código de 6 dígitos do exame',
        inputAttrs: {
          maxlength: 6
        },
        confirmText: 'Adicionar',
        cancelText: 'Cancelar',
        onConfirm: async value => {
          let instance_code = localStorage.getItem('exam_info_codes')
          if (instance_code) {
            let codes = instance_code.split(',')
            if (codes.indexOf(value) == -1) {
              codes.push(value)
              localStorage.setItem('exam_info_codes', codes.join(','))
            }
          } else {
            localStorage.setItem('exam_info_codes', value)
          }
          this.loadDashboard()
        }
      })
    },
    async loadExamsInfo() {
      console.log('loadExamsInfo')
      try {
        await this.loadDashboard()
      } catch (e) {
        console.error(e)
      } finally {
        // Force some delay to show the loading screen
        setTimeout(() => {
          this.loading = false
        }, 1250)
      }
    },
    async loadDashboard() {
      this.exams = []
      let instance_key = localStorage.getItem('exam_info_keys')
      let instance_code = localStorage.getItem('exam_info_codes')
      console.log('instance_key', instance_key)
      console.log('instance_code', instance_code)
      let result = await FormServices.getProjectionInfo(
        instance_key ? instance_key.split(',') : [],
        instance_code ? instance_code.split(',') : []
      )
      console.log('getProjectionInfo DATA', result)
      Object.entries(result).forEach(([, value]) => {
        this.exams.push(value)
      })
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "examNotOpen": "O exame ainda não foi aberto a participantes.",
      "loading": "A carregar...",
      "error": "Ocorreu um erro ao carregar o exame",
      "no_exams": "Sem exames a mostrar",
      "no_exams_instructions": "Clique em adicionar para projectar a informação de um exame"
    },
    "en": {
      "examNotOpen": "The exam was not open yet.",
      "loading": "Loading...",
      "error": "There was an error loading the exam",
      "no_exams": "No exams to show",
      "no_exams_instructions": "Click in add exam to project the information of an exam"
    }
  }
  </i18n>
