var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-full flex gap-1.5 pr-3 pl-1.5 py-1 items-center",class:[
    {
      'bg-red-500 text-white': _vm.remainingSeconds <= 0,
      'bg-examstatus-orange text-white': _vm.remainingSeconds > 0 && _vm.remainingSeconds < _vm.criticalSecondsleft,
      'bg-gray-200 bg-opacity-60': _vm.type == 'normal' && _vm.remainingSeconds >= _vm.criticalSecondsleft,
      'bg-primary bg-opacity-95': _vm.type == 'management' && _vm.remainingSeconds >= _vm.criticalSecondsleft
    },
    `text-${_vm.size}`
  ]},[_c('fw-icon-clock-line',{class:{
      'text-white': _vm.remainingSeconds < _vm.criticalSecondsleft,
      'text-gray-500': _vm.type == 'normal' && _vm.remainingSeconds >= _vm.criticalSecondsleft,
      'text-white': _vm.type == 'management' && _vm.remainingSeconds >= _vm.criticalSecondsleft,
      'w-5 h-5': _vm.size == 'sm',
      'w-7 h-7': _vm.size == 'lg',
      'w-9 h-9': _vm.size == 'xl'
    }}),_c('div',{staticClass:"timer font-semibold leading-5 text-center transition-all w-24",class:{
      'text-white': _vm.remainingSeconds < _vm.criticalSecondsleft,
      'text-gray-800': _vm.type == 'normal' && _vm.remainingSeconds >= _vm.criticalSecondsleft,
      'text-white': _vm.type == 'management' && _vm.remainingSeconds >= _vm.criticalSecondsleft,

      'w-20': _vm.ellapsedDays == 0 && _vm.ellapsedHours > 1 && _vm.size == 'sm',
      'w-24': _vm.ellapsedDays == 0 && _vm.ellapsedHours > 1 && _vm.size == 'lg',
      'w-28': _vm.ellapsedDays == 0 && _vm.ellapsedHours > 1 && _vm.size == 'xl',
      'w-12': _vm.ellapsedDays == 0 && _vm.ellapsedHours <= 0 && _vm.size == 'sm',
      'w-20': _vm.ellapsedDays == 0 && _vm.ellapsedHours <= 0 && _vm.size == 'lg',
      'w-24': _vm.ellapsedDays == 0 && _vm.ellapsedHours <= 0 && _vm.size == 'xl'
    }},[(_vm.remainingSeconds < 0)?_c('span',[_vm._v("-")]):_vm._e(),(_vm.ellapsedDays > 0)?_c('span',{staticClass:"whitespace-nowrap mr-1"},[_vm._v(_vm._s(_vm.ellapsedDays)+"d")]):_vm._e(),(_vm.ellapsedHours > 0)?_c('span',[_vm._v(_vm._s(_vm._f("time")(_vm.ellapsedHours))),_c('span',{staticClass:"opacity-80"},[_vm._v(":")])]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("time")(_vm.ellapsedMinutes))),_c('span',{staticClass:"opacity-80"},[_vm._v(":")]),_vm._v(_vm._s(_vm._f("time")(_vm.ellapsedSeconds)))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }