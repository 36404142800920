<template>
  <div
    class="rounded-full flex gap-1.5 pr-3 pl-1.5 py-1 items-center"
    :class="[
      {
        'bg-red-500 text-white': remainingSeconds <= 0,
        'bg-examstatus-orange text-white': remainingSeconds > 0 && remainingSeconds < criticalSecondsleft,
        'bg-gray-200 bg-opacity-60': type == 'normal' && remainingSeconds >= criticalSecondsleft,
        'bg-primary bg-opacity-95': type == 'management' && remainingSeconds >= criticalSecondsleft
      },
      `text-${size}`
    ]"
  >
    <fw-icon-clock-line
      :class="{
        'text-white': remainingSeconds < criticalSecondsleft,
        'text-gray-500': type == 'normal' && remainingSeconds >= criticalSecondsleft,
        'text-white': type == 'management' && remainingSeconds >= criticalSecondsleft,
        'w-5 h-5': size == 'sm',
        'w-7 h-7': size == 'lg',
        'w-9 h-9': size == 'xl'
      }"
    />
    <div
      class="timer font-semibold leading-5 text-center transition-all w-24"
      :class="{
        'text-white': remainingSeconds < criticalSecondsleft,
        'text-gray-800': type == 'normal' && remainingSeconds >= criticalSecondsleft,
        'text-white': type == 'management' && remainingSeconds >= criticalSecondsleft,

        'w-20': ellapsedDays == 0 && ellapsedHours > 1 && size == 'sm',
        'w-24': ellapsedDays == 0 && ellapsedHours > 1 && size == 'lg',
        'w-28': ellapsedDays == 0 && ellapsedHours > 1 && size == 'xl',
        'w-12': ellapsedDays == 0 && ellapsedHours <= 0 && size == 'sm',
        'w-20': ellapsedDays == 0 && ellapsedHours <= 0 && size == 'lg',
        'w-24': ellapsedDays == 0 && ellapsedHours <= 0 && size == 'xl'
      }"
    >
      <span v-if="remainingSeconds < 0">-</span>
      <span v-if="ellapsedDays > 0" class="whitespace-nowrap mr-1">{{ ellapsedDays }}d</span>
      <span v-if="ellapsedHours > 0">{{ ellapsedHours | time }}<span class="opacity-80">:</span></span>
      <span>{{ ellapsedMinutes | time }}<span class="opacity-80">:</span>{{ ellapsedSeconds | time }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TimeCounter',
  filters: {
    time: function(value) {
      if (!value) return '00'
      if (value < 10) {
        return '0' + value
      }
      return value
    }
  },
  props: {
    remainingSeconds: {
      type: Number,
      default: 0
    },
    criticalSecondsleft: {
      type: Number,
      default: 120
    },
    size: {
      type: String,
      default: 'sm'
    },
    type: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      ellapsedDays: 0,
      ellapsedHours: 0,
      ellapsedMinutes: 0,
      ellapsedSeconds: 0
    }
  },
  watch: {
    remainingSeconds(newValue) {
      this.calculateTimeLeft(newValue)
    }
  },
  created() {
    this.calculateTimeLeft(this.remainingSeconds)
  },
  methods: {
    calculateTimeLeft(secondsLeft) {
      let diff = Math.abs(secondsLeft)
      let days = Math.floor(diff / 86400)
      let hours = Math.floor((diff % 86400) / 3600)
      let minutes = Math.floor((diff % 3600) / 60)
      let seconds = Math.floor((diff % 3600) % 60)
      this.ellapsedDays = days
      this.ellapsedHours = hours
      this.ellapsedMinutes = minutes
      this.ellapsedSeconds = seconds
    }
  }
}
</script>

<style>
.bg-examstatus-active {
  background-color: #41a89a;
}

.bg-examstatus-red {
  background-color: #ef6161;
}

.bg-examstatus-orange {
  background-color: #f09000;
}

.bg-examstatus-inactive {
  background-color: #383838;
}
</style>
